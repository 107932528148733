import React, { Component } from 'react';

class Tracks extends Component {
  render() {

    if(this.props.data){
      var tracks = this.props.data.tracks.map(function(tracks){
        var projectImage = 'images/tracks/'+tracks.image;
        return <div key={tracks.title} className="columns tracks-item">
           <div className="item-wrap">
            <a href={tracks.url} style={{display: "table-cell"}} target="_blank" rel="noreferrer" title={tracks.title}>
               <img alt={tracks.title} src={projectImage} />
               <div className="overlay">
                  <div className="tracks-item-meta">
                 <h5>{tracks.title}</h5>
                     <p>{tracks.category}</p>
                  </div>
                </div>
              <div className="link-icon"><i className="fa fa-link"></i></div>
            </a>
          </div>
        </div>
      })
    }

    return (
      <section id="tracks">

      <div className="row">

         <div className="twelve columns collapsed">
            <h2>Released Tracks</h2>           

            <div id="tracks-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
                {tracks}
            </div>
          </div>
      </div>
   </section>
    );
  }
}

export default Tracks;
