import React, { Component } from 'react';
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class LatestRelease extends Component {
   render() {
    
      // if(this.props.data){
      //    var testimonials = this.props.data.testimonials.map(function(testimonials){
      //      return  <li key={testimonials.user}>
      //          <blockquote>
      //             <p>{testimonials.text}</p>
      //             <cite>{testimonials.user}</cite>               
      //          </blockquote>
      //       </li>
      //    }) 
          
      //  }         
       if(this.props.data){
         var newLink = this.props.data.latestRelease;
         var networks= this.props.data.latest.map(function(network){      
            return <li key={network.name}><a href={network.url} style={{display: "table-cell"}} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={["fab", network.className]} size="lg"></FontAwesomeIcon></a></li>
          })
       }
   
       return (
         <section id="latest">
            <div className="row-container">               
               <div className="column center">
               <h2>Latest Release</h2>
                  <div className="player-wrapper">
                     <ReactPlayer url= {newLink} width= "100%"/> 
                  </div>                  
               </div>
               <div className="column">
                  <ul className="social">
                        {networks}
                  </ul>
               </div> 
   
               {/*
               <div className="two columns header-col">
                  <h1><span>Client Testimonials</span></h1>
               </div>
               
               <div className="ten columns flex-container">
                     <ul className="slides">
                         {/*{testimonials}
                     </ul>                  
                   
               </div>
               */}
            </div>
               

      </section>
       );
     }
   }

export default LatestRelease;
