import React, { Component } from 'react';
import videoLoop from '../video/tarotLogo.mp4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(fab);

class Header extends Component {
  render() {

    if(this.props.data){
      //var name = this.props.data.name;
      //var occupation= this.props.data.occupation;
      //var description= this.props.data.description;
      //var city= this.props.data.address.city;
      var networks= this.props.data.social.map(function(network){      
        return <li key={network.name}><a href={network.url} style={{display: "table-cell"}} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={["fab", network.className]} size="lg"></FontAwesomeIcon></a></li>
      })
    }

    return (
      <header id="home">

      <nav id="nav-wrap">

         <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
	      <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
            {/*<li><a className="smoothscroll" href="#resume">Resume</a></li>*/}
            {/*<li><a className="smoothscroll" href="#testimonials">Testimonials</a></li>*/}
            <li><a className="smoothscroll" href="#latest">Latest Release</a></li>            
            <li><a className="smoothscroll" href="#about">About</a></li>
            <li><a className="smoothscroll" href="#tracks">Tracks</a></li> 
            {/*<li><a className="smoothscroll" href="#portfolio">Works</a></li>*/}            
            <li><a className="smoothscroll" href="#contact">Contact</a></li>
         </ul>

      </nav>

      <div className="row banner">
         <video autoPlay muted loop className="banner-video">
               <source src={videoLoop} type="video/mp4"/>
               Your browser does not support the video tag. I suggest you upgrade your browser.
         </video>  
         <div className="banner-text">
            {/*<h1 className="responsive-headline">I'm {name}.</h1>
            <h3>I'm a {city} based <span>{occupation}</span>. {description}.</h3>
            <hr />*/}
            <ul className="social">
               {networks}
            </ul>
         </div>
      </div>

      <p className="scrolldown">
         <a className="smoothscroll" href="#latest"><i className="icon-down-circle"></i></a>
      </p>

   </header>
    );
  }
}

export default Header;
